import axios from "axios";
import { Message, MessageBox } from "element-ui";
import { removeLocalStorage } from "./local-storage";

const service = axios.create({
  //baseURL: "http://localhost:18080/",
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      let res = response.data;
      if (res.code != undefined) {
        if (res.code === 200) {
          return res.data;
        }
        return Promise.reject(res);
      }
      return res;
    } else if (response.status === 401) {
      removeLocalStorage("Authorization");
      MessageBox.alert("授权已失效", "警告", {
        confirmButtonText: "确定",
        type: "error"
      });
      return Promise.reject("error");
    } else if (response.status === 500) {
      return Promise.reject(response);
    } else {
      MessageBox.alert("未处理异常", "错误", {
        confirmButtonText: "确定",
        type: "error"
      });
      return Promise.reject("error");
    }
  },
  error => {
    console.log(error);
    Message({
      message: "连接超时",
      type: "error",
      duration: 5000
    });
    return Promise.reject(error);
  }
);

export default service;
