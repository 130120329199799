import Vue from "vue";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./theme/index.css";
import request from "@/utils/request";
import App from "./App.vue";
import router from "./router";

Vue.use(ElementUI, { size: "small" });

Vue.config.productionTip = false;

const startApp = function() {
  request({ url: "server.json", method: "get" }).then(data => {
    request.defaults.baseURL = data.baseURL;
    Vue.prototype.$baseURL = data.baseURL;
    Vue.prototype.$imAPI = data.imAPI;
    Vue.prototype.$authUrl = data.authUrl;
    Vue.prototype.$socket = data.socket;
    new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  });
};
startApp();
