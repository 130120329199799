import Vue from "vue";
import VueRouter from "vue-router";
import { getLocalStorage, removeLocalStorage } from "@/utils/local-storage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home.vue"),
    meta: {
      keepalive: true,
      login: true
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue")
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth.vue")
  },
  {
    path: "*",
    redirect: {
      name: "home"
    }
  }
];

function getPath() {
  let path = location.pathname;
  return path.substring(1, path.lastIndexOf("/"));
}

const router = new VueRouter({
  mode: "history",
  base: getPath(),
  routes
});

router.beforeEach((to, from, next) => {
  const { Authorization } = getLocalStorage("Authorization");
  if (!Authorization) {
    if (to.meta.login) {
      next({ name: "login", query: { redirect: to.fullPath } });
      return;
    }
  }
  const { Expire } = getLocalStorage("Expire");
  if (Expire && new Date().getTime() > Expire * 1000) {
    console.log("Expire");
    removeLocalStorage("Authorization", "Expire");
    next({ name: "login", query: { redirect: to.fullPath } });
    return;
  }
  next();
});

export default router;
